import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { OutletModel } from '../models/entity-child.model';
import { PaginationModel } from '../models/pagination.model';

@Injectable({
  providedIn: 'root',
})
export class ChildEntityService {
  baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  addEntityChild(outlet: OutletModel): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/api/Outlet/AddOutlet`, outlet);
  }

  getEntityChildList(
    paginationModel: PaginationModel<OutletModel>
  ): Observable<any> {
    let parameter = new HttpParams();
    parameter = parameter.set(
      'pageNumber',
      paginationModel.pageNumber.toString()
    );
    parameter = parameter.set(
      'pageLength',
      paginationModel.pageLength.toString()
    );
    parameter = parameter.set(
      'activeStatus',
      paginationModel.activeStatus.toString()
    );
    if (paginationModel.searchString != null) {
      parameter = parameter.set(
        'searchString',
        paginationModel.searchString.toString()
      );
    }

    return this.http.get<any>(`${this.baseUrl}/api/Outlet/Paged`, {
      params: parameter,
    });
  }

  getChildEntityByEntity(entityId: number): Observable<any> {
    return this.http
      .get<any>(`${this.baseUrl}/api/Outlet/GetOutletByEntityId?${entityId}`)
      .pipe(map((response: any) => response));
  }

  getEntityChildById(systemId: number): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/api/Outlet/${systemId}`);
  }

  updateEntityChild(Outlet: OutletModel): Observable<any> {
    return this.http.patch<any>(`${this.baseUrl}/api/Outlet/`, Outlet);
  }

  patchOutlet(outletId: number, updates: Map<string, object>): Observable<any> {
    let updatesObject = Object.fromEntries(updates);
    return this.http.patch<any>(
      `${this.baseUrl}/api/Outlet/${outletId}`,
      updatesObject
    );
  }

  deleteEntityChild(outletId: number) {
    return this.http.post<any>(
      `${this.baseUrl}/api/Outlet/DeleteOutlet?outletId=${outletId}`,
      {}
    );
  }

  checkEntityChildUrl(customUrl: string): Observable<any> {
    const encodedUrl = encodeURIComponent(customUrl);
    return this.http.get<any>(
      `${this.baseUrl}/api/Outlet/CheckOutletUrl?customUrl=${encodedUrl}`
    );
  }

  statusUpdate(systemIdLIst: Array<number>, status: boolean): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}/api/Outlet/SetStatusAsync?status=${status}`,
      systemIdLIst
    );
  }

  getOutletType(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/api/Outlet/GetOutletType`);
  }

  getOutletByShortCode(code: string): Observable<any> {
    return this.http.get<any>(
      `${this.baseUrl}/api/Outlet/GetByShortCode/${code}`
    );
  }
}
